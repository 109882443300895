import { Link } from "gatsby"
import React from "react"
import { Container } from "react-bootstrap"
import ImageModule from "../../modules/image-render"
import "./TeamListing.scss"
import ScrollAnimation from "react-animate-on-scroll"

const TeamListing = ({ teamList }) => {
  return (
    <div className="team-listing-wrapper">
      <Container>
        <div className="team-section">
          {teamList?.map((team, i) => {
            let processedImages = JSON.stringify({})
            if (team?.node?.imagetransforms?.image_Transforms) {
              processedImages = team?.node.imagetransforms.image_Transforms
            }
            var imagename = "team.image.tile_image"
            return (
              <ScrollAnimation animateIn="animate__slideInUp" duration={.5} animateOnce delay={(i+1) * 100} offset={25}>
              <div className="team-card">
                <Link to={`${team?.node?.slug}/`}>
                  <div className="image-section img-zoom">
                   
                    <ImageModule
                      ImageSrc={team?.node?.image}
                      altText={`${
                        team?.node?.image?.alternativeText
                          ? team?.node?.image?.alternativeText
                          : team?.node?.name
                      } banner`}
                      imagetransforms={processedImages}
                      renderer="pic-src"
                      imagename={imagename}
                      strapi_id={team?.node?.strapi_id}
                      classNames="team-image"
                    />
                  </div>
                </Link>
                <Link to={`${team?.node?.slug}/`}>
                  <p className="bold-text">{team?.node?.name}</p>
                </Link>
                <p className="designation">{team?.node?.designation}</p>
              </div>
              </ScrollAnimation>
            )
          })}
        </div>
      </Container>
    </div>
  )
}

export default TeamListing
