import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BreadcrumbModule from "../components/BreadcrumbModule/BreadcrumbModule"
import Head from "../components/Head/Head"
import TeamListing from "../components/TeamListing/TeamListing"
import Banner_Cta from "../components/Banner_Cta/Banner_Cta"
import { homeValuation, instantValuation } from "../site/urls"

const TeamLandingPageTemplate = ({ data }) => {
  const teamData = data?.strapiPage
  const teamList = data?.allStrapiTeam?.edges

  return (
    <Layout popularSearch={teamData?.select_popular_search?.title}>
        <div className="bottom-right-image-home">
        <div className="hover-block">
          <div className="block-elements">
           {/* <Link to={instantValuation}>Instant Online Valuation</Link>
           <div className="vertical-line"></div> */}
           <Link to={homeValuation}>Home Visit Valuation</Link>
           </div>
        </div>
        <div className="banner-img"></div>
       </div>
      <SEO
        title={
          teamData?.seo?.metaTitle ? teamData?.seo?.metaTitle : teamData?.title
        }
        description={
          teamData?.seo?.metaDescription
            ? teamData?.seo?.metaDescription
            : teamData?.title
        }
      />
      <BreadcrumbModule
        choose_menu={teamData.choose_menu}
        pagename={teamData.title}
      />
      <Head title={teamData?.title} content={teamData?.content} />
      <TeamListing teamList={teamList} />
      {teamData.add_page_modules?.map((item, i) => {
        return (
          <div>
            {item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_CTA_BANNER" && (
              <Banner_Cta {...item} />
            )}
          </div>
        )
      })}
    </Layout>
  )
}

export default TeamLandingPageTemplate

export const query = graphql`
  query ($page_id: String) {
    strapiPage(id: { eq: $page_id }) {
      ...PageFragment
      imagetransforms {
        banner_section_banner_image_Transforms
      }
      add_page_modules {
        ... on STRAPI__COMPONENT_PAGE_MODULES_CTA_BANNER {
          __typename
          ...CTA_BANNERFragment
        }
        ... on STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE {
          select_module
          __typename
        }
      }
    }
    allStrapiTeam(sort: {fields: rank, order: ASC}, filter: { publish: { eq: true } }) {
      edges {
        node {
          ...TeamFragment
        }
      }
    }
  }
`
